// =========================================/
// Global Admin
// =========================================/

.page-admin {
	background-color: $admin-color-bg-light;
	h1,h2,h3,h4,h5,h6 {
		font-family: $admin-header-font;
	}
	p,li,input,select,textarea {
		font-family: $admin-body-font;
	}
	a {
		color: $admin-color-primary;
		&:hover {
			color: $admin-color-primary;
			text-decoration: underline;
		}
	}
	label {
		font-size: 14px;
		font-weight: 900;
	}
	.page-admin-main {
		padding: 30px 0px;
		@include global-width;
		min-height: calc(100vh - 130px);
	}
}

.admin-no-results {
	border-top: 1px dashed $admin-color-bg-medium;
	border-bottom: 1px dashed $admin-color-bg-medium;
	text-align: center;
	padding: 30px;
}
.show-input {
	display: block !important;
	width: 100%;
  padding: 8px;
  border: 1px solid $admin-color-bg-medium;
  margin: 5px 0px 10px;
}
.wrong-password {
	span {
		cursor: pointer;
		color: $color-primary;
		&:hover {
			text-decoration: underline;
		}
	}
}

// =========================================/
// Block
// =========================================/

.block {
	background-color: #fff;
	box-shadow: 0px 2px 2px #d3d3d3;
	padding: 40px;
	border-radius: 5px;
	margin-bottom: 50px;
	h1 {
		font-size: 1.5em;
		margin-bottom: 1em;
	}
	p {
		margin-bottom: 1.5em;
	}
	h3 {
		font-size: 1.3em;
		margin-bottom: 1.5em;
	}
	.tabs {
		@include flex-container(stretch,center);
		p {
			margin: 0px;
			padding: 10px 20px;
			border-radius: 3px;
			cursor: pointer;
			flex-grow: 1;
			text-align: center;
			&:hover {
				background-color: #f2f2f2
			}
		}
		.active {
			background-color: $admin-color-secondary !important;
			color: #fff;
		}
	}
	.section {
		margin-bottom: 30px;
		h4 {
			font-size: 1.3em;
			margin-bottom: 15px;
		}
		.desc {
			margin-bottom: 15px;
		}
		input {
			width: 100%;
			padding: 8px;
			border: 1px solid #d3d3d3;
		}
	}
	.section:last-child {
		margin-bottom: 0px;
	}
}

// =========================================/
// Content -> Header
// =========================================/

.page-admin {
	.admin-content {
		width: 100%;
		.atbs-header {
			h2 {
				font-size: 2em;
				font-weight: 600;
				margin: 0px 0px 10px 20px;
				@include admin-mq(m) {
					margin: 0px 0px 10px 0px;
				}
			}
			.atbs-header-sub {
				@include flex-container(space-between,center);
				padding: 0px 20px;
				margin-bottom: 20px;
				@include admin-mq(m) {
					padding: 0px;
				}
				.left {
					@include flex-container(flex-start,center);
					@include admin-mq(m) {
						width: 100%;
					}
					.url-header {
						padding: 5px;
						font-size: .85em;
						border-radius: 3px;
						border: 1px solid #d3d3d3;
						background-color: #f9f9f9;
						width: 300px;
						margin-right: 15px;
						@include admin-mq(m) {
							width: calc(100% - 79px);
						}
					}
					svg {
						width: 27px;
						fill: #a9a9a9;
						padding: 5px;
						border-radius: 3px;
						cursor: pointer;
						&:hover {
							background-color: #f2f2f2;
						}
					}
					svg ~ svg {
						margin-left: 10px;
					}
					svg.active {
						fill: $color-white;
						background-color: $admin-color-secondary;
					}
				}
				p {
					margin: 0px;
					font-size: .75em;
					@include admin-mq(m) {
						display: none;
					}
				}
			}
		}
		.buttons {
			@include flex-container(space-between, center);
			margin-top: 10px;
			input[type=submit] {
				@include transition;
				padding: 10px 20px;
				cursor: pointer;
				background-color: darken($color-secondary,20%);
				color: $color-white;
				border: 0px;
				border-radius: 3px;
				display: inline-block;
				font-size: 1em;
				line-height: 1em;
				&:hover {
					background-color: darken($color-secondary, 15%);
				}
			}
			.delete {
				padding: 10px 20px;
				font-size: 1em;
				line-height: 1em;
				background-color: #a9a9a9;
				color: #fff;
				border-radius: 3px;
				margin: 0px;
				cursor: pointer;
				&:hover {
					background-color: #333;
				}
			}
		}
	}
}

// =========================================/
// Content -> Form/Blocks
// =========================================/

.page-admin {
	.admin-content {
		form {
			@include flex-container;
			position: relative;
			padding-bottom: 90px;
			@include admin-mq(l) {
				padding-bottom: 112px;
			}
			@include admin-mq(s) {
				padding-bottom: 150px;
			}
			.ac-block {
				position: relative;
				width: 100%;
				.fa-times {
					position: absolute;
					top: 0;
					right: 0;
					font-size: 1em;
					padding: 10px;
					color: #a9a9a9;
					cursor: pointer;
					&:hover {
						color: #333;
					}
				}
				h2 {
					margin-bottom: .5em;
					.title-helper {
						display: none;
						margin-left: 8px;
						position: relative;
						@include admin-mq(l) {
							display: none !important;
						}
						i {
							font-size: .5em;
					    vertical-align: top;
					    cursor: pointer;
						}
						.title-content {
							display: none;
							position: absolute;
							top: 0;
							left: 15px;
							width: max-content;
							font-size: .65em;
							font-weight: 400;
							margin: 0px;
							padding: 5px;
							border-radius: 2px;
							max-width: 500px;
							color: gray;
							background-color: #f2f2f2;
						}
						.title-content.active {
							display: inline-block;
						}
					}
				}
				p {
					font-size: .8em;
					font-style: italic;
					color: #a9a9a9;
					line-height: 1.5em;
					margin-bottom: 1em;
				}
				input {
					width: 100%;
					padding: 8px;
					border: 1px solid #d3d3d3;
					font-size: .9em;
					display: none;
				}
				input.uneditable {
					background-color: #f9f9f9;
				}
				.dates-editor {
					@include flex-container;
					.item {
						width: calc(50% - 6px);
						margin-bottom: 12px;
						position: relative;
						display: none;
						@include admin-mq(m) {
							width: 100%;
						}
						input {
							width: 100%;
							padding-right: 25px;
						}
						i {
							position: absolute;
							right: 0;
							padding: 11px 10px 9px 0px;
						}
					}
				}
				.dates-editor-range {
					.item {
						input {
							padding-right: 8px;
						}
					}
				}
				.select-images {
					@include transition;
					padding: 5px 15px;
					cursor: pointer;
					background-color: $color-primary;
					color: $color-white;
					border: 0px;
					border-radius: 3px;
					display: inline-block;
					margin: 10px 10px 0px 0px;
					font-style: unset;
					&:hover {
						background-color: darken($color-primary, 5%);
					}
				}
				.list {
					@include flex-container(flex-start,center);
					padding-top: 12px;
					p {
						margin: 0px 10px 10px 0px;
				    background-color: #fff;
				    box-shadow: 1px 1px 2px #d9d9d9;
				    padding: 6px 8px;
				    border-radius: 3px;
				    font-size: .95em;
				    font-style: unset;
				    color: unset;
						i {
							cursor: pointer;
					    position: relative;
					    font-size: .9em;
					    padding: 0px;
					    margin-left: 8px;
						}
					}
				}
			}
			.ac-block.active {
				h2 {
					.title-helper {
						display: inline-block;
					}
				}
				.dates-editor {
					.item {
						display: block;
					}
				}
			}
		}
	}
}

// =========================================/
// Content -> Form/Blocks -> Description
// =========================================/

.page-admin {
	.admin-content {
		form {
			.ac-block {
				p.description {
					margin: 12px 0 0;
					display: none;
				}
			}
			.ac-block.active {
				p.description {
					display: block;
				}
			}
		}
	}
}

// =========================================/
// Content -> Form/Blocks -> Add date
// =========================================/

.page-admin {
	.admin-content {
		form {
			.ac-block {
				.date-add {
					display: none;
					p {
						@include transition;
						padding: 5px 15px;
						cursor: pointer;
						background-color: $color-primary;
						color: $color-white !important;
						border: 0px;
						border-radius: 3px;
						display: inline-block;
						margin: 9px 10px 0px 0px;
						font-style: unset;
						i {
							margin-right: 5px;
						}
						&:hover {
							background-color: darken($color-primary, 5%);
						}
					}
				}
			}
			.ac-block.active {
				.date-add {
					display: block;
				}
			}
		}
	}
}

// =========================================/
// Content -> Form/Blocks -> List
// =========================================/

.page-admin .ac-block-list {
	&:not(.active) { select { display: none; } }
	select {
		width: 100%;
		padding: 8px;
		font-size: .9em;
	}
}

// =========================================/
// Content - New Section
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.acb-new-section {
				@include flex-container;
				input[type='text'], input[type='number'] {
					width: calc(50% - 6px);
				}
				div {
					width: 100%;
					@include flex-container;
				}
				select {
					width: calc(50% - 6px);
					height: 40px;
					margin: 12px 0px;
					border: 1px solid #d3d3d3;
					font-size: .9em;
					@include admin-mq(s) {
						width: 100%;
					}
				}
				.add-section-submit {
					display: inline-block;
					width: max-content;
					background-color: $color-primary;
					transition: all 0.1s;
					padding: 10px 20px;
					font-style: unset;
					cursor: pointer;
					color: #fff;
					border: 0px;
					border-radius: 3px;
					display: inline-block;
					margin: 10px 10px 0px 0px;
					&:hover {
						background-color: darken($color-primary, 5%);
					}
				}
				.list-selected {
					display: block;
					.list-sel {
						position: relative;
						padding: 10px 30px 10px 10px;
						border: 1px solid #d3d3d3;
						width: 100%;
						max-width: 500px;
						@include flex-container(space-between,center);
						margin-bottom: 5px;
						p {
							margin: 0px;
						}
						input {
							font-size: .9em;
							padding: 5px;
							width: 44%;
						}
						i {
							cursor: pointer;
							margin-top: 5px;
						}
					}
				}
				.list-options {
					@include flex-container(flex-start,center);
					margin-top: 10px;
					p {
						font-style: unset;
						color: unset;
						padding: 8px 20px;
						margin-right: 5px;
						border-radius: 3px;
						background-color: #d3d3d3;
						cursor: pointer;
						&:hover {
							background-color: #c8c8c8;
						}
					}
				}
			}
		}
	}
}

// =========================================/
// Listing
// =========================================/

.admin-content-listing {
	@include flex-container;
	.acl-header {
		width: 100%;
		margin-bottom: 30px;
		@include flex-container(space-between,center);
		.left {
			@include flex-container(flex-start,center);
			@include admin-mq(m) {
				width: 100%;
				margin-bottom: 20px;
			}
			h1 {
				font-size: 1.5em;
				font-weight: 500;
				margin-right: 20px;
			}
			a {
				display: inline-block;
				border: 2px solid #e9e9e9;
				padding: 5px 20px;
				color: #999;
				border-radius: 3px;
				text-decoration: none;
				@include transition;
				&:hover {
					border: 2px solid #333;
					background-color: #333;
					color: #fff;
				}
			}
		}
		input {
			font-size: .9em;
			padding: 5px;
			width: calc(33% - 10px);
			@include admin-mq(l) {
				width: calc(50% - 10px);
			}
			@include admin-mq(m) {
				width: 100%;
			}
		}
	}
	.atb-single {
		width: calc(33% - 10px);
		@include admin-white-background;
		margin: 0px 0px 20px;
		color: $color-black !important;
		text-decoration: none !important;
		@include transition(.2s);
		@include flex-container(space-between,center);
		&:hover {
			box-shadow: 0px 0px 10px #a9a9a9;
		}
		@include admin-mq(l) {
			width: calc(50% - 10px);
		}
		@include admin-mq(m) {
			width: 100%;
		}
		h1,h2 {
			margin: 0px;
			font-size: 24px;
			line-height: 30px;
		}
		p {
			font-size: 14px;
			font-weight: 400;
			margin: 0px;
			font-style: italic;
			color: #a9a9a9;
			width: 100%;
			margin-top: 10px;
		}
		a {
			display: inline-block;
			border: 2px solid #d3d3d3;
			padding: 5px 15px;
			border-radius: 3px;
			color: #333;
			&:hover {
				background-color: #333;
				color: #fff;
			}
		}
	}
}
.atbs-header .atbsh-links a,
.admin-block-container .cancel-link,
.admin-block-container input[type=submit] {
  margin: 0px;
  border: 2px solid $admin-color-bg-medium;
  padding: 5px 15px;
  border-radius: 3px;
  color: $admin-color-black;
  line-height: 16px;
  margin-left: 3px;
  background: none;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}
.admin-block-container {
	.cancel-link {
		font-family: $admin-body-font;
	}
	.acul-delete {
		color: #333;
		font-size: 1em;
		&:hover {
			background-color: $admin-color-primary;
			border: 2px solid $admin-color-primary;
			color: $color-white;
		}
	}
}
.atbs-header .atbsh-links a:hover,
.admin-block-container .cancel-link:hover,
.admin-block-container input[type=submit]:hover {
	cursor: pointer;
	background-color: $admin-color-bg;
	border: 2px solid $admin-color-bg;
	color: $admin-color-white;
}

// =========================================/
// Vertical Listing
// =========================================/

.admin-vert-list {
	.avl-tabs {
		@include flex-container(center,center);
		width: 100%;
		p {
			margin: 0px;
			padding: 7px 14px;
			border-radius: 5px 5px 0px 0px;
			cursor: pointer;
		}
		p.avct-active {

		}
	}
	.avl-post {
		@include admin-white-background;
		display: none;
		@include admin-mq(l) {
			width: 100% !important;
		}
		h3 {
			font-size: 1.2em;
			font-weight: 600;
			margin-bottom: 15px;
		}
		p:first-child {
			font-weight: 600;
		}
		em {
			font-style: italic;
		}
		p:last-child {
			margin: 0px;
		}
	}
	.avlp-active {
		display: block;
	}
	.avl-admin-post {
		display: block;
		p {
			font-weight: 400 !important;
		}
		div {
			p {
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

// =========================================/
// Admins
// =========================================/

.page-admin-main {
	.admin-vert-list {
		@include flex-container(space-between);
		.avl-post {
			padding: 20px !important;
			width: calc(50% - 10px);
			margin: 0px 0px 20px;
			p {
				margin-bottom: 10px;
			}
			p:last-child {
				margin-bottom: 0px;
			}
			div {
				p {
					color: $admin-color-primary;
					font-weight: 600;
				}
			}
		}
	}
}

// =========================================/
// Pages
// =========================================/

.admin-block-container {
	.atbs-header {
		margin-bottom: 20px;
		h2 {
			font-size: 1.5em;
			font-weight: 900;
			span {
				padding-left: 10px;
				font-size: .8em;
				font-style: italic;
				font-weight: 500;
			}
		}
	}
	.table-editor {
		border: 1px solid #d3d3d3;
		border-bottom: 0px;
		width: 100%;
		overflow-x: scroll;
		table {
			margin: 0px;
			border: 1px solid #d3d3d3;
			border-bottom: 0px;
			border-right: 0px;
			border-radius: 0px;
			width: 100%;
		}
		tr {
			display: flex;
			margin-top: -2px;
		}
		td {
			background-color: inherit !important;
			border: inherit !important;
			padding: 0px !important;
			margin-left: -2px;
			flex: 1;
			min-width: 200px;
			min-height: 100px;
		}
		textarea {
			border: 1px solid #d3d3d3;
			display: block;
			font-size: 14px;
			padding: 12px;
			width: 100%;
			height: 100%;
		}
	}
}
.table-actions span {
	display: inline-block;
  margin: 10px 10px 0px 10px;
  font-size: .9em;
  color: $admin-color-primary;
  cursor: pointer;
  &:hover {
  	text-decoration: underline;
  }
  i {
  	padding-right: 5px;
  }
}
.ac-block {
	@include admin-white-background;
	margin: 0px 0px 20px 0px;
	padding: 17px;
	h2 {
		font-size: 1.2em;
		font-weight: 900;
		p {
			margin: 10px 0px 20px;
			font-size: 13px;
			font-style: italic;
			font-weight: 400;
		}
	}
	.atbs-editor {
		width: 100%;
		.ql-snow {
			.ql-tooltip.ql-editing input[type=text] {
				width: unset;
			}
		}
		.ql-tooltip.ql-editing {
			font-size: .9em;
			a.ql-preview {
				max-width: unset;
			}
			a.ql-action {
				&:after {
					margin-left: 0px;
				}
			}
			input {
				min-width: 350px;
			}
		}
	}
	.textarea-inline {
		width: 100%;
    border: 1px solid #a9a9a9;
    height: 80px;
    padding: 15px;
    font-size: .9em;
	}
	.input-inline {
		display: inline-block;
		font-size: .9em;
	}
	.date {
		margin-bottom: 10px;
		span {
			display: inline-block;
			font-weight: 900;
			margin: 0px 10px;
		}
		input {
			padding: 5px;
		}
	}
}
.atbs-footer {
	@include flex-container(flex-end);
	padding: 10px 0px;
}
.atbs-footer form {
	display: inline-block;
}
.ac-block-active {
	.ql-toolbar {
		position: fixed;
		top: 0;
		background-color: #fff;
		z-index: 1;
	}
	.ql-container {
		padding-top: 42px;
	}
}

// =========================================/
// Images
// =========================================/

.acb-image,
.acb-images {
	position: relative;
	.acb-images-current {
		@include flex-container;
	}
	.acbi-active {
		height: 250px;
		width: 250px;
		background-size: 90%;
		background-position: center;
		background-repeat: no-repeat;
		background-color: #f2f2f2;
		border-radius: 5px;
		border: 2px solid #f2f2f2;
		position: relative;
		@include flex-container(center,center);
		&:hover {
			cursor: pointer;
			border: 2px solid gray;
			.arrows {
				opacity: 1;
			}
		}
		i {
			position: absolute;
			top: 0;
			right: 0;
			color: $admin-color-primary;
			cursor: pointer;
			z-index: 1;
			&:hover {
				color: $admin-color-secondary;
			}
		}
		.arrows {
			opacity: 0;
			@include transition(.5s);
			i {
				position: unset !important;
				font-size: 3em;
				margin: 0px 5px;
				color: $color-white !important;
				text-shadow: 0px 0px 10px $color-black;
				@include transition;
				&:hover {
					color: $admin-color-primary !important;
				}
			}
		}
	}
	.acbi-container {
		flex-wrap: wrap;
		justify-content: space-between;
		display: none;
		.image-container {
			@include flex-container;
			width: 100%;
			.acbic-single {
				width: calc(25% - 5px);
				height: 150px;
				background-size: 90%;
				background-position: center;
				background-repeat: no-repeat;
				background-color: #f2f2f2;
				border-radius: 5px;
				margin-bottom: 10px;
				border: 2px solid #f2f2f2;
				position: relative;
				@include admin-mq(l) {
					width: calc(33% - 5px);
				}
				@include admin-mq(m) {
					width: calc(50% - 5px);
				}
				@include admin-mq(s) {
					width: 100%;
					height: 300px;
				}
				&:hover {
					cursor: pointer;
					border: 2px solid gray;
				}
			}
		}
		.image-container-grid-large {
			.acbic-single {
				width: calc(50% - 10px);
				height: 300px;
				@include mq(m) {
					width: 100%;
				}
			}
		}
	}
	.acbi-container-show {
		display: flex;
	}
}
.acbic-new {
	width: 100%;
	height: 100px;
	background-color: #f2f2f2;
	border-radius: 5px;
	margin: 10px 0px;
	border: 2px dashed $admin-color-bg-medium;
	cursor: pointer;
	@include flex-container(center, center);
	i {
		color: #a9a9a9;
		font-size: 28px;
	}
	p {
		margin: 0px !important;
	}
	.dropzone {
		height: 100%;
		@include flex-container(center, center);
		cursor: pointer;
		i {
			margin-top: 40px;
		  font-size: 30px;
		  color: $admin-color-bg-medium;
		}
		.dz-default {
			font-size: 13px;
		  width: 100%;
		  text-align: center;
		  margin-bottom: 30px;
		  color: gray;
		}
	}
	.dz-preview {
		display: none;
	}
}

// =========================================/
// Images Listing
// =========================================/

.acb-images {
	.acb-images-current {
		.select-image {
			cursor: pointer;
			background-color: $color-primary;
			color: #fff;
			border-radius: 5px;
			padding: 5px 15px;
			display: inline-block;
			font-style: unset;
		}
		.acbi-active {
			height: 150px;
			width: calc(25% - 5px);
			margin-bottom: 10px;
			position: relative;
			@include admin-mq(m) {
				width: calc(50% - 5px);
			}
			@include admin-mq(s) {
				width: 100%;
			}
			i {
				position: absolute;
				top: 0;
				right: 0;
				color: $admin-color-primary;
				cursor: pointer;
				&:hover {
					color: $admin-color-secondary;
				}
			}
		}
	}
}

// =========================================/
// Sign out
// =========================================/

.admin-container .ac-signout {
	font-size: 18px;
	width: 100%;
	text-align: center;
	padding: 100px 0px;
}

// =========================================/
// Events
// =========================================/

.acbi-multiple {
	display: flex;
}
.acbi-multiple label {
	margin-right: 10px;
	font-size: 14px;
	width: 33%;
}
.acbi-multiple input {
	padding: 8px;
  margin-top: 5px;
  width: 100%;
}
.admin-content {
	.ac-new-event {
		@include transition;
		width: 200px;
		border: 2px solid $admin-color-bg-medium;
		padding: 15px;
		display: inline-block;
		text-align: center;
		color: $admin-color-black;
		border-radius: 3px;
		font-size: 1.1em;
		cursor: pointer;
		background-color: $admin-color-white;
		font-weight: 500;
		margin-bottom: 20px;
	}
}
.admin-content .ac-new-event:nth-child(2) {
	margin-left: 20px;
}
.admin-content .ac-new-event i {
	padding-left: 10px;
}
.admin-content .ac-new-event:hover {
	background-color: $admin-color-black;
	border: 2px solid $admin-color-black;
	color: $admin-color-white;
}
.ac-update-links {
	@include flex-container;
}
.ac-update-links a {
	margin: 0px;
  border: 2px solid $admin-color-bg-medium;
  padding: 5px 15px;
  border-radius: 3px;
  color: $admin-color-black;
  line-height: 16px;
  margin-left: 3px;
  background: none;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}
.ac-update-links a:hover {
	background-color: $admin-color-black;
	border: 2px solid $admin-color-black;
	color: $admin-color-white;
}
.ac-update-links span {
	font-size: .8em;
	margin-left: 20px;
	color: gray;
}

// =========================================/
// Meta
// =========================================/

.ac-block-image-auto textarea {
	display: none;
}
.input-count {
	margin: 0px;
	font-size: 12px;
	text-align: right;
	display: none;
}

// =========================================/
// Checkbox
// =========================================/

.checkbox-container {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  @include flex-container(normal, center);
  margin: 0px;
  height: 25px;
  input {
	  position: absolute;
	  opacity: 0;
	  cursor: pointer;
	  height: 0;
	  width: 0;
	  &:checked ~ .checkmark {
		  background-color: $admin-color-secondary;
		  &:after {
			  display: block;
			}
		}
	}
	.checkmark {
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 25px;
	  width: 25px;
	  background-color: #d3d3d3;
	  &:after {
		  content: "";
		  position: absolute;
		  display: none;
		  left: 9px;
		  top: 5px;
		  width: 5px;
		  height: 10px;
		  border: solid white;
		  border-width: 0 3px 3px 0;
		  -webkit-transform: rotate(45deg);
		  -ms-transform: rotate(45deg);
		  transform: rotate(45deg);
		}
	}
	&:hover input ~ .checkmark {
	  background-color: #ccc;
	}
}

// =========================================/
// Blog
// =========================================/

.hide-textarea {
	display: none;
}

// =========================================/
// Alerts
// =========================================/

.ac-block-input {
	select {
		display: block;
		font-weight: 300;
		font-size: 14px;
		height: 40px;
		font-family: $admin-body-font;
		width: 33%;
		background-color: $admin-color-white;
		border: 1px solid $admin-color-bg-medium;
	}
	input[type=color] {
		padding: 0px;
		border: 1px solid $admin-color-bg-medium;
		height: 30px;
	}
}

// =========================================/
// Ordering
// =========================================/

.atb-order-container {
	background-color: #fff;
  box-shadow: 1px 1px 1px #d3d3d3;
  border-radius: 5px;
  padding: 30px;
  margin: 0px auto 20px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  .atb-order-single {
  	@include flex-container(space-between, center);
  	width: 100%;
  	padding: 15px 0px;
  	border-bottom: 1px solid #d3d3d3;
  	p {
  		margin: 0px;
  	}
  	i {
  		cursor: pointer;
  		font-size: 1.5em;
  		margin-right: 20px;
  	}
  }
  .atb-order-single:last-child {
  	border-bottom: 0px;
  }
}

// =========================================/
// Super
// =========================================/

.admin-super-container {
	@include admin-white-background;
	h1 {
		width: 100%;
	  font-weight: 900;
	  font-size: 18px;
	}
	label {
		display: block;
		font-size: 1.1em;
		font-weight: 900;
		margin-bottom: 30px;
		input {
			display: block !important;
			width: 100%;
		  padding: 8px;
		  border: 1px solid $admin-color-bg-medium;
		  margin: 5px 0px 10px;
		  font-size: .9em;
		}
		select {
			display: block;
			width: 100%;
		  height: 37px;
		  border: 1px solid $admin-color-bg-medium;
		  margin: 5px 0px 10px;
		  font-size: .9em;
		}
	}
	input[type=submit] {
		@include transition;
		width: 150px;
		background-color: $admin-color-secondary;
		color: $admin-color-white;
		border: 0px !important;
		border-radius: 3px;
		display: inline-block;
		margin: 30px 0px 0px;
		&:hover {
			background-color: $admin-color-secondary-dark;
		}
	}
	.super-add {
		font-weight: 900;
		cursor: pointer;
		&:hover {
			color: $admin-color-primary;
		}
	}
	.asc-multiple {
		@include flex-container;
		label {
			width: 49%;
			margin-bottom: 0px;
			input {
				width: 100%;
			}
		}
	}
}

// =========================================/
// ac-block
// =========================================/

.page-admin {
	.admin-content {
		form {
			.ac-block {
				width: calc(33% - 10px);
				cursor: pointer;
				border: 3px solid $color-white;
				@include admin-mq(l) {
					width: calc(50% - 10px);
				}
				@include admin-mq(m) {
					width: 100%;
				}
				&:hover {
					box-shadow: 2px 2px 3px #d3d3d3;
				}
				.acb-description {
					margin-bottom: 0px;
				}
			}
			.ac-block.active {
				width: 100%;
				cursor: default;
				box-shadow: 1px 1px 1px #d3d3d3 !important;
				.acb-description {
					margin-bottom: 1em;
				}
			}
			.ac-block.block-focused {
				@include animation(blockFocused,2s);
			}
		}
	}
}
@keyframes blockFocused {
	0% { border: 3px solid $admin-color-primary; }
	100% { border: 3px solid $color-white; }
}

// =========================================/
// Input
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			input {
				display: none;
			}
		}
		.ac-block.active {
			input {
				display: block;
			}
		}
		.ac-block.url-duplicate {
			h2 {
				span.duplicate-url-text {
					color: $admin-color-primary;
					font-weight: 400;
			    font-size: .8em;
			    margin-left: 20px;
			    font-style: italic;
				}
			}
			input {
				border: 1px solid $admin-color-primary !important;
			}
		}
	}
}

// =========================================/
// Select
// =========================================/

.ac-block {
	.acb-select {
		select {
			width: 49%;
			height: 40px;
			font-size: .9em;
			@include admin-mq(s) {
				width: 100%;
			}
		}
	}
}

// =========================================/
// List
// =========================================/

.ac-block {
	p.list-temp-value {
		display: none !important;
		font-size: .7em !important;
		margin: 7px 0px 0px !important;
		color: lighten($admin-color-secondary, 10%) !important;
		@include animation(listTempPulse, 3s, infinite);
	}
	.list {
		display: none !important;
	}
}
.ac-block.active {
	p.list-temp-value {
		display: block !important;
	}
	.list {
		display: flex !important;
	}
}
@keyframes listTempPulse {
	0% { opacity: .3; }
	50% { opacity: 1; }
	100% { opacity: .3; }
}

// =========================================/
// Color
// =========================================/

.ac-block {
	.acb-color {
		input {
			width: 100%;
			height: 50px;
		}
	}
}

// =========================================/
// Text
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.atbs-editor, .acb-shortcodes {
				display: none;
			}
		}
		.ac-block.active {
			.atbs-editor, .acb-shortcodes {
				display: block;
			}
		}
	}
}

// =========================================/
// Image
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.acb-image {
				display: none;
			}
		}
		.ac-block.active {
			.acb-image {
				display: block;
			}
		}
	}
}

// =========================================/
// Images
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.acb-images {
				display: none;
			}
		}
		.ac-block.active {
			.acb-images {
				display: block;
			}
		}
	}
}

// =========================================/
// Table
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.table, .table-resize {
				display: none !important;
			}
			.table, .table-resize {
				margin: 40px 0px 5px;
				.row {
					@include flex-container(stretch);
					position: relative;
					padding-left: 25px;
					border-right: 1px solid #d3d3d3;
					.column {
						position: relative;
						flex-grow: 1;
						width: 10%;
						textarea {
							padding: 8px;
							font-size: .9em;
							border-right: 0px;
							border-bottom: 0px;
							width: 100%;
							height: 100%;
						}
						.add-column {
							position: absolute;
							top: -26px;
							left: -8.5px;
							height: 100%;
							opacity: .1;
							&:hover {
								opacity: 1;
							}
							i {
								font-size: .8em;
								position: relative;
								z-index: 1;
								color: $admin-color-white;
								background-color: $admin-color-secondary;
								@include flex-container(center,center);
								border-radius: 100%;
								height: 17px;
								width: 17px;
								cursor: pointer;
								padding-top: 1px;
							}
							div {
								position: absolute;
								width: 100%;
								left: 8px;
								margin: 0px;
								border: 0px;
								width: 2px;
								background-color: $admin-color-secondary;
								height: 100%;
							}
						}
						.remove-column {
							position: absolute;
							top: -26px;
							width: 100%;
							@include flex-container(center);
							i {
								font-size: .8em;
								color: $admin-color-white;
								background-color: $admin-color-primary;
								@include flex-container(center,center);
								border-radius: 100%;
								height: 17px;
								width: 17px;
								padding-left: 1px;
								padding-top: 1px;
								cursor: pointer;
								opacity: .1;
								&:hover {
									opacity: 1;
								}
							}
						}
						.add-column-last {
							left: unset;
							right: -8.5px;
						}
					}
					.add-row {
						position: absolute;
						top: -8.5px;
						left: 0;
						width: 75px;
						opacity: .1;
						&:hover {
							opacity: 1;
						}
						i {
							font-size: .8em;
							position: relative;
							z-index: 1;
							color: $admin-color-white;
							background-color: $admin-color-secondary;
							@include flex-container(center,center);
							border-radius: 100%;
							height: 17px;
							width: 17px;
							padding-left: .5px;
							cursor: pointer;
						}
						hr {
							position: absolute;
							width: 100%;
							top: 9px;
							margin: 0px;
							border: 0px;
							border-top: 2px solid $admin-color-secondary;
						}
					}
					.add-row-bottom {
						top: unset;
						bottom: -7px;
					}
					.remove-row {
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						@include flex-container(flex-start,center);
						i {
							font-size: .8em;
							color: $admin-color-white;
							background-color: $admin-color-primary;
							@include flex-container(center,center);
							border-radius: 100%;
							height: 17px;
							width: 17px;
							padding-left: 1px;
							padding-top: 1px;
							cursor: pointer;
							opacity: .1;
							&:hover {
								opacity: 1;
							}
						}
					}
				}
				.row:last-child {
					textarea {
						border-bottom: 1px solid #d3d3d3;
					}
				}
			}
			.table-resize {
				@include flex-container(flex-start,center);
				margin-top: 20px;
				input {
					width: 40px;
					padding: 4px 8px;
				}
				span {
					font-weight: 600;
					margin: 0px 10px;
				}
				p {
					background-color: gray;
					color: #fff;
					padding: 5px 10px;
					border-radius: 3px;
					margin: 0px 0px 0px 10px;
					font-style: unset;
					color: $admin-color-white !important;
					&:hover {
						background-color: #a9a9a9;
						cursor: pointer;
					}
				}
			}
		}
		.ac-block.active {
			.table {
				display: block !important;
			}
			.table-resize {
				display: flex !important;
			}
		}
	}
}

// =========================================/
// Select
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.acb-select {
				display: none;
			}
		}
		.ac-block.active {
			.acb-select {
				display: block;
			}
		}
	}
}

// =========================================/
// List
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.list{
				display: none;
			}
		}
		.ac-block.active {
			.list {
				display: flex;
			}
		}
	}
}

// =========================================/
// Code
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.code-textarea {
				width: 100%;
				height: 90px;
				padding: 8px;
				font-size: .9em;
				display: none;
			}
		}
		.ac-block.active {
			.code-textarea {
				display: block;
			}
		}
	}
}

// =========================================/
// New
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.acb-new-section {
				display: none;
			}
		}
		.ac-block.active {
			.acb-new-section {
				display: flex;
			}
		}
	}
}

// =========================================/
// Blocks
// =========================================/

.page-admin {
	.admin-content {
		.ac-block-blocks {
			.sections {
				.section {
					@include flex-container(space-between,flex-start);
					margin-bottom: 20px;
					position: relative;
					.left {
						width: 60px;
						@include admin-mq(m) {
							width: 40px;
						}
						.number {
							font-size: 2em;
							color: #d3d3d3;
							text-align: center;
							@include admin-mq(m) {
								font-size: 1.2em;
							}
						}
						.arrows {
							i {
								display: block;
								font-size: 2em;
								text-align: center;
								cursor: pointer;
								color: #d3d3d3;
								&:hover {
									color: $color-black;
								}
							}
						}
					}
					.section-content {
						width: calc(100% - 60px);
						border: 2px solid;
						border-radius: 5px;
						padding: 30px;
						@include admin-mq(m) {
							width: calc(100% - 45px);
						}
						.item {
							margin-bottom: 15px;
							h2 {
								font-size: 1em;
								.title-helper {
									.title-content {
										font-size: .75em;
									}
								}
							}
							.acb-image {
								.acbi-active {
									width: 200px;
									height: 200px;
								}
							}
						}
					}
				}
			}
			.add {
				cursor: pointer;
				margin: 20px 0px 0px 60px;
				i {
					margin-right: 5px;
				}
			}
			.blocks-toggle {
				cursor: pointer;
				margin: 20px 0px;
				color: $admin-color-primary !important;
				font-style: unset !important;
				font-weight: 600 !important;
				display: none;
				svg {
					width: 13px;
					margin: 0px 0px -2px 15px;
					margin-left: 15px;
				}
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.ac-block-blocks.active {
			.blocks-toggle {
				display: block;
			}
		}
	}
}